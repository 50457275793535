.App-logo {
    opacity: 0.25;
    height: 60vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 10s linear;
    }
  }

.App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    0% {
      transform: scale(3) rotate(0deg);
    }
    50% {
      transform: scale(1.5) rotate(720deg);
      opacity: 0.05;
    }
    100% {
      transform: scale(3) rotate(1480deg);
    }
  }

title{
  color: white;
}
.bottom-container{
  text-align: center;
  position: relative;
  top: 80vh;
}
.skip {
  font-size: calc(10px + 2vmin);
  margin: auto;
  width: fit-content;
  border: 1.75px solid #2e2e2e;
  cursor: pointer;
  letter-spacing: 0.225rem;
  overflow: hidden;
  padding: 9px 12px 12px 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition:
    background 5s cubic-bezier(0.19, 1, 0.22, 1),
    border 1s cubic-bezier(0.19, 1, 0.22, 1),
    color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  user-select: none;
}

.skip a {
	color: #969696;
	font-family: "Varela Round";
	text-decoration: none;
}

.skip .mask {
  border: red solid 1 px;
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.5);
	height: 100px;
	position: absolute;
  left: 0px;
	transform: translate3d(-120%, -50px, 0) rotate3d(0, 0, 1, 45deg);
	transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
	width: 100%;
}

.skip .shift {
  position: relative;
  top: -3px;
	display: inline-block;
	transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
}

.skip:hover {
	background-color: rgba(255, 255, 255, 0.05);
	border-color: #fff;
	box-shadow: 0 0 5px rgba(255, 245, 245, 0.8);
	transition: background 0s;
}

.skip:hover a {
	color: #fff;
}

.skip:hover .mask {
	background-color: #fff;
	transform: translate3d(120%, -100px, 0) rotate3d(0, 0, 1, 90deg);
}

.skip:hover .shift {
	transform: translateX(5px);
}

.skip:active {
	background-color: #fff;
}

.skip:active a {
	color: #202020;
}
/*
.skip:hover {
	background-image: url('http://.com/img/codepen/savvy.jpg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}*/

.intro-background{
  width: 100vw;
  height: 100vh;
  background-color: #202020;
  background-image: radial-gradient(35% 100% ellipse at top center,transparent,#000 100%);
  /* -webkit-transition: all 3s linear; */
  /* -moz-transition: all 3s linear; */
  /* -o-transition: all 3s linear; 
  transition: all 3s linear; */
  opacity: 1;
  /* visibility:visible; */
}
.outro-background{
  background-color: none;
  background-image: radial-gradient(35% 100% ellipse at top center,transparent,#000 100%);
  opacity: 1;
  -webkit-transition: all 2s linear;
  -moz-transition: all 2s linear;
  -o-transition: all 2s linear;
  transition: all 2s linear;
  /* visibility: hidden; */
}
.entersite-background{
    background-color: none;
    background-image: radial-gradient(35% 100% ellipse at top center,transparent,#000 100%);
    opacity: 0;
    -webkit-transition: all 2s linear;
    -moz-transition: all 2s linear;
    -o-transition: all 2s linear;
    transition: all 2s linear;
}

/* #IntroParticleCanvas{
  background-color: #2e2e2e;
} */