.App {
  position: fixed;
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  color: white;
}
.App-header {
  /*background-color: linear-gradient(to bottom right, black, rgba(25,25,25,1), rgba(35,25,25,1));*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  justify-content: center;
	margin: 0;
	padding: 0;
}

/* ---- reset ---- */ 
canvas{ 
  display: block; 
  vertical-align: bottom; 
} 
/* ---- particles.js container ---- */ 
#particles-js{
  z-index: -1; 
  background-color: #202020;
	background-image: radial-gradient(35% 100% ellipse at top center, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 100%);
	transition: background-color 4s cubic-bezier(0.19, 1, 0.22, 1);
  position:absolute; 
  width: 100%; 
  height: 100%; 
  background-repeat: no-repeat; 
  background-size: cover; 
  background-position: 50% 50%; 
} 
/* ---- stats.js ---- */ 
.count-particles{ 
  /*background: #000022;*/ 
  position: absolute; 
  top: 48px; 
  left: 0; 
  width: 80px; 
  color: #13E8E9; 
  font-size: .8em; 
  text-align: left; 
  text-indent: 4px; 
  line-height: 14px; 
  padding-bottom: 2px; 
  font-family: Helvetica, Arial, sans-serif; 
  font-weight: bold; 
} 
.js-count-particles{ 
  font-size: 1.1em; 
} 
#stats, .count-particles{ 
  -webkit-user-select: none; 
  margin-top: 5px; 
  margin-left: 5px; 
} 
#stats{ 
  border-radius: 3px 3px 0 0; 
  overflow: hidden; 
} 
.count-particles{ 
  border-radius: 0 0 3px 3px; 
}

/* pages */
.page-container{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
.Resume{
  /* flex: 0 0 100%; */
  width: 100vw;
  height: 100vh;
}
.container-iframe{
  height: calc(100% - 150px);
  width: 800px;
}
.home{
  font-size: calc(10px + 2vmin);
  margin: auto;
  padding-left: 10vw;
  padding-right: 10vw;
}
img.meme{
  max-width: 300px;
  max-height: 300px;
}
img.paypal{
  max-height: 40px;
}
.supportme{
  /* border: red 1px solid; */
  margin: auto;
} 
.supportme form:hover{
  filter: invert(100%);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* SOCIAL BUTTONS */
.flex-center {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adjust this value to change the spacing */
}
.icon-3d {
  cursor: pointer;
  margin: 15px;
  -webkit-animation: icon3d 200ms 10;
  animation: icon3d 200ms 10;
}
.social-icons a:hover{
  filter: invert(50%);
}
.game-1{
  height: fit-content !important;
}










































.share-list {
  display: flex;
  flex-direction: row;
}

.share-list a {
  border-radius: 100px;
  padding: 5px 5px 0px 5px;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
  color: white;

}
.share-list img{
  filter: invert(100%);
  width: 40px;
}
.share-list a:hover{
  filter: invert(50%);
}
a.fb-h { background: #3B5998; }
a.tw-h { background: #00acee; }
a.li-h { background: #0077B5; }
a.re-h { background: #FF5700; }
a.pi-h { background: #c8232c; }


.share-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}