.spinning-image {
    animation: spin 10s linear infinite;
    position: absolute
}
#intro-shop{
    margin: auto;
    background-image: url("../../public/kartel-bg.png");
    background-size: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#intro-shop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: black; /* Or any color you prefer */
}

#Canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.spinning-image {
  animation: spin 10s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}


.mesh{
    border: solid 5px red;
}

.logo-top.animated-logo {
  width: 30vw;
  height: auto;
  margin-bottom: 20px;
  animation: fadeIn 1s ease-out forwards;
}

.logo-bottom.confidential-logo {
  width: 40vw;
  height: auto;
  margin-top: 20px;
  opacity: 0;
  animation: fadeIn 4s ease-out forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Mobile vertical view styles */
@media (max-width: 768px) and (orientation: portrait) {
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
  }

  .logo-top.animated-logo,
  .logo-bottom.confidential-logo {
    object-fit: contain;
    object-position: center;
    position: absolute;
    transform: translateY(-50%);
  }

  .logo-top.animated-logo {
    top: 40%;
  }

  .logo-bottom.confidential-logo {
    bottom: 29%;
  }
}