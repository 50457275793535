

#shop-container {
    background-color: #fff;
    min-height: 100vh;
    min-width: 100vw;
    overflow-y: auto;
    padding: 20px 20px 50vh
}

.main-content {
    color: #000
}

.shop-class {
    text-align: center
}

.price-tag {
    color: #000;
    font-size: 24px
}

.price-tag span {
    margin-bottom: 15px;
    padding-bottom: 5px
}

.price-tag img {
    margin-top: 5px;
    padding-left: 5px;
    z-index: 2
}

.shop-class p {
    color: #000;
    font-size: 24px;
    margin-bottom: 20px
}

.shop-banner {
    width: 50%
}

.product-item {
    margin-bottom: 10px;
    padding: 10px;
    width: 300px
}

.product-cart {
    align-items: center;
    color: #000;
    display: flex;
    flex-direction: row;
    justify-content: first baseline;
    margin-bottom: 40px
}

#shop-container>div.cart-sidebar>div>div>img {
    width: 150px
}

.product-line img {
    width: 1vw;
    z-index: 2
}

.product-item h3 {
    margin: 0
}

.payment-methods {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: auto;
    width: 100%
}

.payment-methods button {
    background-color: #007bff;
    border: none;
    border-radius: 15px;
    color: #d9d9d9;
    cursor: pointer;
    display: block;
    font-weight: 700;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%
}

.cart-total {
    font-weight: 700
}

.shop-logo {
    bottom: 10px;
    left: 10px;
    position: fixed;
    width: 10%;
    z-index: 0
}

.home-btn {
    left: 20px
}

.home-btn,.toggle-cart-btn {
    background-color: none;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    position: fixed;
    top: 20px;
    z-index: 1000
}

.toggle-cart-btn {
    right: 20px
}

.product-slideshow {
    align-items: center;
    display: flex;
    justify-content: center
}

.horizontal-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center
}

.slideshow-item {
    margin: 50px
}

.img-slideshow {
    width: 50vw;
    z-index: 2
}

.product-img {
    width: 300px;
    z-index: 2
}

.icon-img {
    cursor: pointer;
    transition: opacity .3s ease,transform .3s ease;
    width: 23px
}

.icon-img:hover {
    transform: scale(1.2);
    transition: opacity .5s ease-in-out
}

.bottom-banner {
    /* background: url(/static/media/BANNER3.cae8c923b4c3d826a505.jfif) top repeat-x; */
    background-repeat: repeat-x;
    background-size: 10vw;
    height: 250px;
    margin-bottom: 10vh;
    margin-top: 10vh;
    width: 100vw;
    z-index: 1
}

.cart-items-container {
    max-height: calc(100% - 40px);
    overflow-y: auto;
    width: 100%
}

.cart-items-container,.cart-sidebar {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.cart-sidebar {
    background-image: linear-gradient(180deg,#e8e6e6e1,#e9e9e9ef);
    box-shadow: -2px 0 4px #0000001a;
    color: #000;
    height: 100vh;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 300px
}

.about img {
    margin: 30px;
    width: 45px
}

@media (max-width: 768px) {
    .main-content {
        padding:0 15px
    }
}