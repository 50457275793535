.Projects * {
  box-sizing: border-box;
}

.Projects .container { 
  height: 100%;
  overflow:auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 500px;
}

.flex-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  gap: 20px;
}

@media (min-width: 768px) {
  .flex-grid-container {
    grid-template-columns: repeat(2, minmax(400px, 1fr));
  }
}

.flex-grid-container > div {
  padding: .5em;
  color: white;
  margin: 10px;
  border-radius: 3px;
  background: rgba(79, 82, 73, 0.281);
  border: 5px solid transparent;
}
.container-game-1{
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  gap: 20px; */
}
.video-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}
.video-item video{
  width:80%;
}

/* Keep existing styles for other elements */
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Add space between items */
}
.web-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  transition: all 0.3s ease;
  text-align: center;
  cursor: pointer;
  padding: 10px;
}
.web-link > *{
  text-align: center;
  margin: auto;
}
a{
  text-decoration: none;
}

.web-link:hover {
  background-color: rgba(79, 82, 73, 0.281);
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.download-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #2f0745;
  color: white;
  border-radius: 5px;
  text-decoration: none;
}

.download-button:hover {
  background-color: #3f00b3;
  cursor: pointer;
}

.slideshow-container {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.slideshow-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}